// DateRangePicker
$(document).ready(function() {

  window.initialise_daterange_picker = function (start, end) {
    $('input#reportrange').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
    $('#start_date').val(start.format('YYYY-MM-DD'));
    $('#end_date').val(end.format('YYYY-MM-DD'));
  }

  window.setup_daterange_picker = function (start_date, end_date) {
    if (start_date && end_date) {
      var start = moment(start_date);
      var end = moment(end_date);
      initialise_daterange_picker(start, end);
    } else {
      var start = moment().subtract(1, 'days'); // This is done so that it defaults to custom range and allows 'Today' to be chosen
      var end = moment();
    }

    $('input#reportrange').daterangepicker(
      {
        autoUpdateInput: false,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
          "format": "DD/MM/YYYY",
          "separator": " - ",
          "applyLabel": "Apply",
          "cancelLabel": "Cancel",
        },
      }, window.initialise_daterange_picker
    );

    $('input#reportrange').on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    });
  }
});
