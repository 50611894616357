
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// Additional JS
require('parsleyjs');
require('select2');
window.daterangepicker = require('daterangepicker');
window.moment = require('moment');
require('icheck');
window.Switchery = require('switchery');
require('masonry-layout');
window.contextMenu = require('jquery-contextmenu');
require('jquery.ui.position');
// require('@fortawesome/fontawesome-free/js/all.js');
// require('@fortawesome/fontawesome-free/js/v4-shims.js');
require('tinymce');
window.Cookies = require('js-cookie');

// Admin Panel additional JS - Might not need some of these
require('perfect-scrollbar');
require('pathseg');
require('chart.js');
require('chartist');
require('flot');
require('raphael');
require('justgage');
require('inputmask');
require('dropify');
require('jquery-asColor');
require('jquery-asColorPicker');
require('jquery-asGradient');
require('bootstrap-datepicker');

// Admin Panel Component JS
require('./components/daterangepicker');
require('./components/form-addons');
require('./components/formpickers');
require('./components/functions');
require('./components/hoverable-collapse');
require('./components/misc');
require('./components/off-canvas');
require('./components/popover');
require('./components/settings');
require('./components/todolist');
require('./components/tooltips');
require('./components/x-editable');

try {
    window.contextMenu = require('jquery-contextmenu');
    window.moment = require('moment');
    window.moment.locale('en');

    window.datetimepicker = require('daterangepicker');
} catch (e) {}

//JS files added by Jonny
// require('./components/jquery.matchHeight.js');
// require('./components/fontawesome-all.js');
require('./components/move.js');
require('./components/jquery.fancybox.min.js');

(function($) {
    'use strict';
    $(function() {
        $('[data-toggle="offcanvas"]').on("click", function() {
            $('.sidebar-offcanvas').toggleClass('active')
        });
    });
})(jQuery);

// Tooltip
$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip({
        container: 'body'
    });

    $('.inputWrap').click(function(){
        var $arrowPoint = $(this).find('.pointTrig');
        var $selectBox = $(this).find('select2-container');
        if ( $selectBox.hasClass('select2-container--focus') ){
            $arrowPoint.toggleClass('fa-angle-down fa-angle-up');
        } else if ($selectBox.hasClass('select2-container--focus')){
            $arrowPoint.toggleClass('fa-angle-up fa-angle-down');
        } else{
        }
    });

    $('.menuTrig').click(function(){
        $('.menuWrapMob').slideToggle();
    });

});

// Password Show/Hide
$(document).ready(function() {
    $('div.password_show_hide').click(function(e){
        e.preventDefault();
        $(this).find('.password_icon').toggleClass('fa-eye fa-eye-slash');
        $(this).siblings('input').attr('type') === 'password' ? $(this).siblings('input').attr('type','text') : $(this).siblings('input').attr('type','password');
    });
});

//Custom Jquery - Jonny
$(document).ready(function(){

    //Hidden Sidebar Toggle - Notifications
    $('#sidebar__toggle--notifications').on('click', function(event) {
        $(this).toggleClass('active');
        $('#sidebar__hidden--notifications').toggleClass('active');
        event.preventDefault();

        //Remove active sidebar and button elements on close of other sidebar toggle
        if ($('.sidebar__hidden--settings').hasClass('active')) {
            $('.sidebar__hidden--settings').removeClass('active');
            $('.site-header__icons--middle').toggleClass('active');
        }
    });

    //Hidden Sidebar Toggle - Settings
    $('#sidebar__toggle--settings').on('click', function(event) {
        $(this).toggleClass('active');
        $('#sidebar__hidden--settings').toggleClass('active');
        event.preventDefault();

         //Remove active sidebar and button elements on close of other sidebar toggle
        if ($('.sidebar__hidden--notifications').hasClass('active')) {
            $('.sidebar__hidden--notifications').removeClass('active');
            $('.site-header__icons--last').toggleClass('active');
        }
    });

    $('.btn.btn--purple.back-button').on('click', function(){
        $('#continue-button > a').removeClass('active');
    });

    //Change BG Colour of div on click on payment type on payment page
    $('.site-content__payments--methods--wrapper').on('click', function(event) {
        if(!$(this).hasClass('active')) {
            $('.site-content__payments--methods--wrapper').removeClass('active');
        }
        $(this).toggleClass('active');
        $(this).parent().find('#continue-button > a').toggleClass('active');
    });

    //Add Class to parent div if input checkbox is checked
    $('.sidebar__hidden--checkbox').on('click', function(event){
        $(this).parent().parent().find('.checkmark').toggleClass('block-checked');
    });

    //Tabs
    $('.tabs__nav span').on('click', function() {
        $([$(this).parent()[0], $($(this).data('href'))[0]]).addClass('active').siblings('.active').removeClass('active');
    });

    // Toggle Mobile Navigation
    $('#toggle').on('click', function(event) {
        $('#main-nav').fadeToggle(200);
        $(this).toggleClass('active');
        $('.site-header').toggleClass('site-header--open');
        $('html, body').toggleClass('fixed');
        event.preventDefault();

        //Remove active sid
        // ebar and button elements on close of mobile toggle
        if ($('.sidebar__hidden, .site-header__icons a').hasClass('active')) {
            $('.sidebar__hidden, .site-header__icons a').removeClass('active');
        }
    });

    // // Init Match Height for non-async widgets
    // $('.mh').matchHeight();
    //
    // // Init Match Height again, but wait until the async widgets have loaded
    // setTimeout(function () {
    //     $('.mh').matchHeight({remove:true});
    //     $('.mh').matchHeight();
    //     $('.mh').matchHeight._update();
    //     // console.log('match height updated');
    // }, 3500);

    //Accordions on Legal Pages
    $('.accordions__block--title').on('click', function(event){
        $(this).toggleClass('active');
        $(this).parent().toggleClass('active');
        $(this).parent().find('.accordions__block--content').slideToggle();
    });


    // // Move Site Header Icons into mobile navigation
    // $('.site-header__icons').move({
    //     breakpoint: 767,
    //     oldLocation: '.site-header__main',
    //     newLocation: '#main-nav ul',
    //     methods: {
    //         o: 'insertAfter',
    //         n: 'insertAfter'
    //     }
    // });
    //
    // //Move Admin Panel button above mobile nav
    // $('#btn--admin_panel').move({
    //     breakpoint: 767,
    //     oldLocation: '.site-header__main .site-header__logo',
    //     newLocation: '#main-nav ul',
    //     methods: {
    //         o: 'insertAfter',
    //         n: 'insertBefore'
    //     }
    // });
    //
    //
    // //Move Online Chat button above mobile nav
    // $('#btn--chat').move({
    //     breakpoint: 767,
    //     oldLocation: '.site-header__main .site-header__logo',
    //     newLocation: '#main-nav ul',
    //     methods: {
    //         o: 'insertAfter',
    //         n: 'insertBefore'
    //     }
    // });
    //
    // //Move Logout button above mobile nav
    // $('#btn--logout').move({
    //     breakpoint: 767,
    //     oldLocation: '.site-header__main .site-header__logo',
    //     newLocation: '#main-nav ul',
    //     methods: {
    //         o: 'insertAfter',
    //         n: 'insertBefore'
    //     }
    // });
    //
    // // Move Site Header Icons into mobile navigation
    // $('.site-header__home').move({
    //     breakpoint: 767,
    //     oldLocation: '.site-header__main .site-header__logo',
    //     newLocation: '#main-nav ul',
    //     methods: {
    //         o: 'insertAfter',
    //         n: 'insertAfter'
    //     }
    // });


    // Tab groups
    $('.tabgroup > div').hide();
    $('.tabgroup > div:first-of-type').show();
    $('.tabs a').click(function(e){
        e.preventDefault();
        var $this = $(this),
            tabgroup = '#'+$this.parents('.tabs').data('tabgroup'),
            others = $this.closest('li').siblings().children('a'),
            target = $this.attr('href');
        others.removeClass('active');
        $this.addClass('active');
        $(tabgroup).children('div').hide();
        $(target).show();

    });

    // HelpHovs
    $('.helphov').hover(function(){
        $(this).parent().find('.helpInfo').fadeToggle();
    });


});


// Select 2
$(document).ready(function() {
    $('.select2').select2({
        theme: "rmg",
        width: 'resolve',
        placeholder: $(this).attr('placeholder'),

    });
    $('.select2-multiple').select2({
        multiple : true,
        theme: "rmg",
        width: 'resolve',
        placeholder: $(this).attr('placeholder'),

    });
});

//iCheck
$(document).ready(function() {
    $('.icheck').iCheck({
        checkboxClass: 'icheckbox_flat-green',
        radioClass: 'iradio_flat-green'
    });
});

//downloads
/**
 * Show the loading animation when a
 * link is clicked or a form is submitted
 */
$(document).ready(function() {
    $('form').submit(function (e) {
        setTimeout(function () {
            $('#loader').fadeIn();
        }, 100);

        if ($(this).hasClass('download')) {
            console.log('here');
            e.preventDefault();
            var params = $(this).serializeArray();
            var xhr = new XMLHttpRequest();
            xhr.open($(this).attr('method'), $(this).attr('action'), true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function () {
                if (this.status === 200) {
                    var filename = "";
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                    }
                    var type = xhr.getResponseHeader('Content-Type');

                    var blob = new Blob([this.response], {type: type});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var URL = window.URL || window.webkitURL;
                        var downloadUrl = URL.createObjectURL(blob);

                        if (filename) {
                            // use HTML5 a[download] attribute to specify filename
                            var a = document.createElement("a");
                            // safari doesn't support this yet
                            if (typeof a.download === 'undefined') {
                                window.location = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = filename;
                                document.body.appendChild(a);
                                a.click();
                            }
                        } else {
                            window.location = downloadUrl;
                        }

                        setTimeout(function () {
                            URL.revokeObjectURL(downloadUrl);
                        }, 100); // cleanup
                    }
                }
            };
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.send($.param(params));

            setTimeout(function () {
                $('#loader').fadeOut();
            }, 100);
        }
    });
});
