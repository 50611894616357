// DateRangePicker
$(document).ready(function() {
// Returns a function, that as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being executed for
// `wait` milliseconds.
  window.debounce = function(func, wait) {
    let timeout;

    // This is the function that is returned and will be executed many times
    // We spread (...args) to capture any number of parameters we want to pass
    return function executedFunction(...args) {

      // The callback function to be executed after
      // the debounce time has elapsed
      const later = () => {
        // clear the timeout to indicate the debounce ended
        // and make sure it is all cleaned up
        clearTimeout(timeout);

        // Execute the callback
        func(...args);
      };

      // This will reset the waiting every function execution.
      // This is the step that prevents the function from
      // being executed because it will never reach the
      // inside of the previous setTimeout
      clearTimeout(timeout);

      // Restart the debounce waiting period.
      // setTimeout returns a truthy value
      timeout = setTimeout(later, wait);
    };
  };
});
